import React from "react"
import { graphql } from 'gatsby'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Parallax } from 'react-parallax'

import Hero from '../components/Hero'
import Layout from '../components/layout'
import CTAs from '../components/CTAs'
import SectionHeading from '../components/SectionHeading'
import NumberedList from '../components/NumberedList'
import Services from "../components/Services"
import SEO from '../components/seo'

const SolutionsPage = ({ data, location }) => {
    const solutions = data.solutions.edges[0].node

    const halfLength = Math.ceil(solutions.approach_steps.length / 2)

    const service_areas = [
        {
            heading: "Strategy",
            subhead: solutions.strategy_subhead,
            capabilities: solutions.strategy_capabilities ? solutions.strategy_capabilities.split(',') : null,
        },
        {
            heading: "Design",
            subhead: solutions.design_subhead,
            capabilities: solutions.design_capabilities ? solutions.design_capabilities.split(',') : null,
        },
        {
            heading: "Engineering",
            subhead: solutions.engineering_subhead,
            capabilities: solutions.engineering_capabilities ? solutions.engineering_capabilities.split(',') : null,
        }
    ]

    return (
        <Layout
            hireUs={false}
            hero={
                <Hero
                    heading={solutions.hero_heading}
                    subhead={solutions.hero_subhead}
                    image={solutions.hero_background}
                />
            }
        >
            <SEO
                title="Solutions"
                path={location.pathname}
            />
            <Container as="section">
                <Services crown="Solutions" heading={solutions.solutions_heading} subhead={solutions.service_subhead} />
            </Container>
            <Container as="section">
                <SectionHeading crown="Our Process" heading={solutions.approach_heading} subhead={solutions.approach_subhead} />
                <Row>
                    <Col>
                        <NumberedList items={solutions.approach_steps.slice(0, halfLength)} startingNumber={1} />
                    </Col>
                    <Col>
                        <NumberedList items={solutions.approach_steps.slice(halfLength)} startingNumber={halfLength + 1} />
                    </Col>
                </Row>
            </Container>
            <section className="serviceList">
                <div className="serviceList__background">
                    <Parallax
                        blur={{ min: -15, max: 15 }}
                        bgImage={solutions.service_background}
                        bgImageAlt="the team"
                        strength={500}
                    >
                        <div style={{ height: '800px' }} />
                    </Parallax>
                </div>
                <Container>
                    <SectionHeading heading={solutions.service_heading} subhead={solutions.service_subhead} className="mb-5 text-light" />
                    <Row>
                        {service_areas.map(area => (
                            <Col lg={4} md={12} className="py-3 py-lg-0 sub-service-margin">
                                <div className="bg-light h-100 pt-4 px-3 border sub-services">
                                    <h3>{area.heading}</h3>
                                    <hr />
                                    <p>{area.subhead}</p>
                                    <ul className={area.capabilities && area.capabilities.length > 25 ? "two-column" : "" }>
                                        {Array.isArray(area.capabilities) ? area.capabilities.map(capability => (
                                            <li className="sub-services-item">
                                                <span>{capability}</span>
                                            </li>
                                        )) : null}
                                    </ul>
                                </div>
                            </Col>
                        ))}
                    </Row>
                </Container>
            </section>
            <Container as="section" fluid={true}  className="text-center bg-gray py-5">
                <Row>
                    <Col>
                        <SectionHeading crown="Our Work" heading={solutions.our_work_heading} />
                        <p>{solutions.our_work_body}</p>
                        <CTAs ctas={solutions.our_work_ctas} />
                    </Col>
                </Row>
            </Container>
        </Layout>
    )
}

export const query = graphql`
{
    solutions: allButterPage(filter: { slug: { eq: "solutions" } }) {
        edges {
            node {
                hero_heading
                hero_subhead
                hero_background
                solutions_heading
                approach_heading
                approach_subhead
                approach_steps {
                    heading
                    body
                }
                service_heading
                service_subhead
                service_background
                strategy_subhead
                strategy_capabilities
                design_subhead
                design_capabilities
                engineering_subhead
                engineering_capabilities
                our_work_heading
                our_work_body
                our_work_ctas {
                    label
                    url
                    icon
                    type
                }
            }
        }
    }
}
`

export default SolutionsPage
