import React from 'react'

const NumberedList = ({items, startingNumber}) => (
    <ul className='numberedList'>
        {items.map((item, i) => (
            <li className='numberedList__item'>
                <div className='numberedList__item__number'>{startingNumber + i}</div>
                <h3 className='numberedList__item__heading h5'>{item.heading}</h3>
                <p className='numberedList__item__body mb-5'>{item.body}</p>
            </li>
        ))}
    </ul>
)

NumberedList.defaultProps = {
    startingNumber: 1
}

export default NumberedList